





















































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class Settings extends Vue {
  loading: boolean = false;
  pagerHeader: any = {
    title: "系统配置",
    desc: "系统配置",
    breadcrumb: ["系统管理", "系统配置"]
  };
  systemForm: any = {
    submitLoading: false,
    paramsModel: {
      systemName: "",
      pageSize: 0,
      takeServiceFeeRate: 0,
      staffFreeMinutes: 0,
      receiptFeeRate: 0
    },
    paramsRules: {
      systemName: [
        { required: true, message: "请输入系统名", trigger: "blur" }
      ],
      pageSize: [
        { required: true, message: "请选择默认每页条数", trigger: "change" }
      ],
      takeServiceFeeRate: [
        { required: true, message: "请输入提现手续费", trigger: "blur" }
      ],
      staffFreeMinutes: [
        { required: true, message: "员人充电免费时间", trigger: "blur" }
      ],
      receiptFeeRate: [
        { required: true, message: "发票收费比例", trigger: "blur" }
      ],
      takeNoAuditAmount:[
        { required: true, message: "提现免审核额度", trigger: "blur" }
      ]
    }
  };
  distributionSettingsForm: any = {
    submitLoading: false,
    paramsModel: {
      platformRate: 0,
      generalAgentRate: 0,
      districtManagerRate: 0,
      agentRate: 0,
      parentAgentRate: 0,
      pointDevRate: 0,
      merchantMaxRate: 0,
      agentMaxBonusRate: 0,
      districtManagerAgentMaxBonusRate: 0,
      generalAgentMaxBonusRate: 0,
      lostDeviceAgentRate:0
    },
    paramsRules: {
      platformRate: [
        { required: true, message: "请输入平台分成比例(0~1)", trigger: "blur" }
      ],
      generalAgentRate: [
        {
          required: true,
          message: "请输入总代理分成比例(0~1)",
          trigger: "blur"
        }
      ],
      districtManagerRate: [
        {
          required: true,
          message: "请输入大区经理分成比例(0~1)",
          trigger: "blur"
        }
      ],
      agentRate: [
        { required: true, message: "请输入投资人比例(0~1)", trigger: "blur" }
      ],
      parentAgentRate: [
        { required: true, message: "请输入上级分成比例(0~1)", trigger: "blur" }
      ],
      pointDevRate: [
        {
          required: true,
          message: "请输入点位开发分成比例(0~1)",
          trigger: "blur"
        }
      ],
      merchantMaxRate: [
        {
          required: true,
          message: "请输入商户可分配比例",
          trigger: "blur"
        }
      ]
    }
  };

  agentSettingsForm: any = {
    submitLoading: false,
    paramsModel: {
      taskRewardEnabled: true,
      taskLevelUpEnabled: true,
      level1MinTaskAmount: 0,
      level2MinTaskAmount: 0,
      level3MinTaskAmount: 0,
      level4MinTaskAmount: 0,
      level5MinTaskAmount: 0,
      level1LeaderReward: 0,
      level1Reward: 0,
      level2LeaderReward: 0,
      level2Reward: 0,
      level3LeaderReward: 0,
      level3Reward: 0,
      level4LeaderReward: 0,
      level4Reward: 0,
      level5LeaderReward: 0,
      level5Reward: 0
    },
    paramsRules: {
      level1MinTaskAmount: [
        { required: true, message: "请输入铜牌升级最低金额", trigger: "blur" }
      ],
      level2MinTaskAmount: [
        { required: true, message: "请输入银牌升级最低金额", trigger: "blur" }
      ],
      level3MinTaskAmount: [
        { required: true, message: "请输入金牌升级最低金额", trigger: "blur" }
      ],
      level4MinTaskAmount: [
        {
          required: true,
          message: "请输入大区经理升级最低金额",
          trigger: "blur"
        }
      ],
      level5MinTaskAmount: [
        { required: true, message: "请输入总代升级最低金额", trigger: "blur" }
      ],
      level1LeaderReward: [
        { required: true, message: "请输入铜牌领导奖励", trigger: "blur" }
      ],
      level1Reward: [
        { required: true, message: "请输入铜牌晋级人奖励", trigger: "blur" }
      ],
      level2LeaderReward: [
        { required: true, message: "请输入银牌领导奖励", trigger: "blur" }
      ],
      level2Reward: [
        { required: true, message: "请输入银牌晋级人奖励", trigger: "blur" }
      ],
      level3LeaderReward: [
        { required: true, message: "请输入金牌领导奖励", trigger: "blur" }
      ],
      level3Reward: [
        { required: true, message: "请输入金牌晋级人奖励", trigger: "blur" }
      ],
      level4LeaderReward: [
        { required: true, message: "请输入大区经理领导奖励", trigger: "blur" }
      ],
      level4Reward: [
        { required: true, message: "请输入大区经理晋级人奖励", trigger: "blur" }
      ],
      level5LeaderReward: [
        { required: true, message: "请输入升总代领导奖励", trigger: "blur" }
      ],
      level5Reward: [
        { required: true, message: "请输入升总代晋级人奖励", trigger: "blur" }
      ]
    }
  };

  activated() {
    this.initForm();
  }
  created() {
    this.initForm();
  }
  async initForm() {
    this.loading = true;
    try {
    //   let zmb = await this.$ajax.get(
    //     "/api/services/app/Configuration/GetClientConfig",
    //     {
    //         params:{
    //         name:'ChargingBatteryRentTimeUnit'
    //     }
    //     }
    //   );
    //   console.log('_____',zmb);
      

      this.systemForm.paramsModel = utils.clone(this.systemSettings);

      let res = await this.$ajax.get(
        "/api/services/app/Configuration/GetDistributionSettings"
      );
      this.distributionSettingsForm.paramsModel = res.data.result;
      res = await this.$ajax.get(
        "/api/services/app/Configuration/GetAgentSettings"
      );
      this.agentSettingsForm.paramsModel = res.data.result;
    } catch (error) {}
    this.loading = false;
  }
  submitSystemParams() {
    (this.$refs.sytemParamsForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.systemForm.submitLoading = true;
        try {
          let res = await this.$ajax.post(
            "/api/services/app/Configuration/ChangeSystemSettings",
            this.systemForm.paramsModel
          );
          if (res.data.success) {
            this.$store.commit(
              "setSystemSettings",
              utils.clone(this.systemForm.paramsModel)
            );
            this.$notify({
              title: "成功",
              message: "系统配置保存成功",
              type: "success"
            });
          }
        } catch (error) {}

        this.systemForm.submitLoading = false;
      }
    });
  }
  submitDistributionSettings() {
    (this.$refs.distributionSettingsForm as any).validate(
      async (valid: boolean) => {
        if (valid) {
          this.distributionSettingsForm.submitLoading = true;
          try {
            let res = await this.$ajax.post(
              "/api/services/app/Configuration/ChangeDistributionSettings",
              this.distributionSettingsForm.paramsModel
            );
            if (res.data.success) {
              this.$notify({
                title: "成功",
                message: "分成配置保存成功",
                type: "success"
              });
            }
          } catch (error) {}

          this.distributionSettingsForm.submitLoading = false;
        }
      }
    );
  }
  submitAgentSettings() {
    (this.$refs.agentSettingsForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.agentSettingsForm.submitLoading = true;
        try {
          let res = await this.$ajax.post(
            "/api/services/app/Configuration/ChangeAgentSettings",
            this.agentSettingsForm.paramsModel
          );
          if (res.data.success) {
            this.$notify({
              title: "成功",
              message: "代理配置保存成功",
              type: "success"
            });
          }
        } catch (error) {}

        this.agentSettingsForm.submitLoading = false;
      }
    });
  }
}
